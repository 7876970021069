import React from 'react'
import Card from 'react-bootstrap/Card';

const Doctor = () => {
    return (
        <>

            <hr />
            <center><h1>OUR  DOCTOR'S</h1></center>
            <br />
            <div className='Doctors'>

                <div className='Doctor2'>
                    <Card style={{ width: '100%', backgroundColor: '#61c4d5' }}>
                        {/* <Card.Img variant="top" src='/' alt="General Surgery" /> */}
                        <Card.Body>
                            <Card.Title>Dr. Priyanka Mishra </Card.Title>
                            <Card.Text>
                                MBBS MS OBS  GYNEC
                            </Card.Text>
                            <Card.Text>
                                Leading Gynecologist Shaping Womens in India Member Fedration of Obs Gynaecologist Societies former Gynaecologist  Fatima Hospital lKO Former Gynaecologist Hind Medical College Lucknow
                            </Card.Text>

                            <Card.Text>
                                Normal Delivery |
                                Caesarean |
                                Hysterectomy
                                Laproscopy |
                                High Risk Pregnancy | Infertility
                                Menopause |
                                Adolescent Problems |
                                Menstrual Problems
                                PCOD |
                                Breast Disease And Lumps


                                <p>0522 4625501, 9140474598</p>
                            </Card.Text>

                        </Card.Body>
                    </Card>
                </div>
                <div className='Doctor2'>
                    <Card style={{ width: '100%', backgroundColor: '#61c4d5' }}>
                        {/* <Card.Img variant="top" src='/' alt="General Surgery" /> */}
                        <Card.Body>
                            <Card.Title>Dr. Ravi Kumar  </Card.Title>
                            <Card.Text>
                                MBBS DNB General Medicine
                            </Card.Text>
                            <Card.Text>
                                Member In American College of Physician (ACP) Faculty in Dept of  Gen Medicine HIMS Ateria.
                            </Card.Text>

                            <Card.Text>
                                General Physician Hypertension Diabetic Fever  Liver & Critical Care Specialist.
                            </Card.Text>
                            <Card.Text>
                                General Physician |
                                Hypertension |
                                Diabetic
                                Fever Liver & Critical Care Specialist
                                <p>0522 4625501, 9140474598</p>
                            </Card.Text>

                        </Card.Body>
                    </Card>
                </div>
                <div className='Doctor2'>
                    <Card style={{ width: '100%', backgroundColor: '#61c4d5' }}>
                        {/* <Card.Img variant="top" src='/' alt="General Surgery" /> */}
                        <Card.Body>
                            <Card.Title>Dr. Nirupama Jaiswal </Card.Title>
                            <Card.Text>
                                MBBS MD PSYCHIATRY
                            </Card.Text>

                            <Card.Text>
                                SPECIALIST IN  PSYCHIATRY
                            </Card.Text>

                        </Card.Body>
                    </Card>
                </div>
                <div className='Doctor2'>
                    <Card style={{ width: '100%', backgroundColor: '#61c4d5' }}>
                        {/* <Card.Img variant="top" src='/' alt="General Surgery" /> */}
                        <Card.Body>
                            <Card.Title>Dr. Puneet Tulsyan </Card.Title>
                            <Card.Text>
                                MBBS DNB
                            </Card.Text>


                            <Card.Text>
                                Ex. Sr. Child Specialist ( KGMU-LUCKNOW  )
                            </Card.Text>

                        </Card.Body>
                    </Card>
                </div>

            </div>

        </>
    )
}

export default Doctor