// src/components/Footer.js
import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
    return (
        <footer className="footer">


            <div className="footer-section">
                <h4>About Us</h4>
                <p>At Parul Hospital, we envision a healthier community where every individual has access to the best medical care. We strive to be the leading healthcare provider in the region, known for our excellence in medical services, innovative treatments, and compassionate care.</p>
            </div>
            <div className="footer-section">
                <h4>Contact</h4>
                <p>Email: 
                parulhospitaljkp@gmail.com</p>
                <p>Phone: +91 9140474598</p>
                <p>Sec-F Jankipuram Near Engineering College Chauraha Lucknow 226021</p>
            </div>
            <div className="footer-section">
                <h4>Follow Us</h4>
               
                <a href="https://www.facebook.com/share/scjFjoSXMxWUjRRG/?mibextid=qi2Omg"><FontAwesomeIcon icon={faFacebook} /> Facebook</a>
                <a href="https://twitter.com"><FontAwesomeIcon icon={faTwitter} /> Twitter</a>
                <a href="https://www.instagram.com/parulhospitaljkp/"><FontAwesomeIcon icon={faInstagram} /> Instagram</a>
                <a href="https://wa.me/919140474598"><FontAwesomeIcon icon={faWhatsapp} /> Whatsapp</a>
            </div>


        </footer>
    );
};

export default Footer;
